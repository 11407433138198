
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useAuth } from "../lib/auth";
import { isRegisteredUserByEmail } from "../utils/service/user";

import { Logo } from "../components/Logo";
import { Card } from "../components/Card";
import EmailSignupForm from "../components/forms/EmailLoginForm";
import { GoogleLoginButton } from "../components/buttons/GoogleLoginButton";
import {
  Box,
  Heading,
  LightMode,
  Spinner,
  Center,
  VStack,
  Stack,
  Text,
  Link,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { getBrowserLocales } from "../utils/usersettings";
import setLanguage from "next-translate/setLanguage";
import PageWrapper from "../components/PageWrapper";

const signin = () => {
  const router = useRouter();
  const { auth, signInWithGoogle, signInWithEmailLinkRequest, loading } =
    useAuth();
  const { t, lang } = useTranslation("signin");

  const [emailLogin, setEmailLogin] = useState(false);
  const [emailLoginValue, setEmailLoginValue] = useState("");
  const [emailNotRegistered, setEmailNotRegistered] = useState(null);

  useEffect(() => {
    if (!auth) return;
    else {
      router.push("/landing");
    }
  }, [auth]);

  useEffect(() => {
    if (!loading) {
      setLanguage(getBrowserLocales()[0].slice(0, 2));
    } else {
      //location.reload();
    }
  }, [loading]);

  async function emailSignIn(e) {
    e.preventDefault();
    const email = e.target[0].value;
    setEmailLoginValue(email);

    await isRegisteredUserByEmail(email).then(async (res) => {
      if (res) {
        await signInWithEmailLinkRequest(email).then(() => {
          window.localStorage.setItem("emailForSignIn", email);
          setEmailLogin(true);
        });
      } else {
        console.error(t("signin:errors.notRegistered"));
        window.localStorage.setItem("emailNotRegistered", email);
        router.reload();
      }
    });
  }

  const emailError = useToast();

  useEffect(() => {
    if (!router.isReady) return;
    if (router.isReady) {
      const email = window.localStorage.getItem("emailNotRegistered");
      if (email) {
        setEmailNotRegistered(email);
        window.localStorage.removeItem("emailNotRegistered");
        emailError({
          description: `${t("signin:errors.notRegistered")} [${email}]`,
          position: "top",
          status: "error",
          duration: 3000,
        });
      }
    }
  }, [router.isReady]);

  return (
    <PageWrapper title='Sign in'>
      <Box
        as='section'
        bg={useColorModeValue("gray.50", "inherit")}
        py='20'
        minH='100vh'
      >
        {emailLogin ? (
          <Card maxW='2xl' mx='auto' minH='50vh' textAlign='center'>
            <Center minH='inherit'>
              <VStack>
                <Heading fontSize='6xl'>🪄</Heading>
                <Text pt='8'>
                  {t("signin:email.magicLink", { email: emailLoginValue })}
                </Text>
                <Box fontSize='sm' pt='24'>
                  <Text
                    fontWeight='medium'
                    color={useColorModeValue("gray.600", "gray.400")}
                  >
                    {t("signin:email.notReceived")}
                  </Text>
                  <Link
                    fontWeight='semibold'
                    color={useColorModeValue("blue.600", "blue.300")}
                    onClick={() => router.push("/")}
                  >
                    {t("signin:email.contact")}
                  </Link>
                </Box>
              </VStack>
            </Center>
          </Card>
        ) : loading ? (
          <Card maxW='2xl' mx='auto' minH='50vh' textAlign='center'>
            <Center minH='inherit'>
              <VStack>
                <Spinner
                  my='auto'
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
                <Text>Loading..</Text>
              </VStack>
            </Center>
          </Card>
        ) : (
          <Card maxW='2xl' mx='auto' minH='50vh' textAlign='center'>
            <Stack maxW='xs' mx='auto' spacing='8'>
              <Stack spacing='3'>
                <Logo />
                <Heading as='h1' letterSpacing='tight'>
                  {t("signin:general.headerSignIn")}
                </Heading>
              </Stack>

              <LightMode>
                <GoogleLoginButton onClick={() => signInWithGoogle()} />
              </LightMode>
              <EmailSignupForm onSubmit={(e) => emailSignIn(e)} />
            </Stack>
          </Card>
        )}
      </Box>
    </PageWrapper>
  );
};

const __Page_Next_Translate__ = signin;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  