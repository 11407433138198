import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { chakra, HTMLChakraProps, useColorModeValue } from "@chakra-ui/system";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import useTranslation from "next-translate/useTranslation";

const EmailSignupForm = (props: HTMLChakraProps<"form">) => {
  const [emailValueValid, setValueValid] = useState(false);
  const [emailValue, setEmailValue] = useState(false);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { t, lang } = useTranslation("signin");
  const validationSchema = yup.object({
    email: yup.string().email().required(),
  });

  function emailChanged(e) {
    setError(false);

    validationSchema
      .isValid({
        email: e.target.value,
      })
      .then((result) => {
        setEmailValue(e.target.value);
        setValueValid(result);
      });
  }

  function submitForm(e) {
    e.preventDefault();
    if (emailValueValid) {
      setSubmitting(true);
      props.onSubmit(e);
    }
  }

  return (
    <chakra.form width='full' {...props} onSubmit={submitForm}>
      <FormControl>
        <FormLabel
          fontWeight='medium'
          fontSize='sm'
          mb='2'
          textAlign='center'
          color={useColorModeValue("gray.600", "gray.400")}
        >
          {t("signin:email.orContinueWith")}
        </FormLabel>
        <Input
          placeholder={t("signin:email.placeholder")}
          _placeholder={{ color: useColorModeValue("gray.600", "gray.400") }}
          type='email'
          onChange={emailChanged}
          isInvalid={error}
        />
        {error ? (
          <FormErrorMessage>{t("signin:email.email-error")}</FormErrorMessage>
        ) : null}
      </FormControl>
      <Button
        type='submit'
        mt='3'
        isFullWidth
        fontSize='sm'
        fontWeight='bold'
        colorScheme='blue'
        isLoading={submitting}
      >
        {t("common:buttons.continue")}
      </Button>
    </chakra.form>
  );
};

export default EmailSignupForm;
